<template>
  <component
    :is="tag"
    v-bind="$attrs"
    :disabled="disabled || loading"
    class="font-medium inline-flex items-center justify-center relative text-center text-sm transition px-6 rounded drop-shadow ring-inset focus:outline-none"
    :class="[colorClasses, sizeClasses]"
    @focus="emit('focus')"
  >
    <ep-spinner v-if="loading" class="absolute" :color="spinnerColor" size="xs" />
    <div
      :class="[
        loadingClasses,
        { 'justify-between': icon, 'justify-center': !icon, 'flex-row-reverse space-x-reverse': leftIcon },
      ]"
      class="flex items-center leading-none space-x-2 flex-1"
    >
      <div v-if="$slots.default" class="flex-1"><slot /></div>
      <EpIcon v-if="icon" :name="icon" />
    </div>
  </component>
</template>

<script>
// eslint-disable-next-line import/order
import { BASE, LG, SM } from '../constants';

export const BLUE = 'blue';
export const OUTLINE = 'outline';
export const PRIMARY = 'primary';
export const ALTERNATIVE = 'alternative';
export const SIZES = [BASE, LG, SM];

export const COLOR_CLASS_MAP = {
  [BLUE]: 'text-white bg-blue-600 hover:bg-blue-800 focus:ring-blue-500 focus:ring-2',
  [OUTLINE]:
    'text-indigo-700 hover:text-white border border-indigo-700 hover:bg-indigo-800 focus:ring-2  focus:ring-indigo-300 dark:text-white',
  [PRIMARY]: 'text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-indigo-500 focus:ring-2',
  [ALTERNATIVE]:
    'border text-gray-900 dark:text-gray-200 dark:border-gray-500 focus:outline-none bg-white dark:bg-gray-750 border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-blue-700 dark:hover:text-gray-50 focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600',
};

export const SIZE_CLASS_MAP = {
  [BASE]: 'h-11',
  [LG]: 'h-14',
  [SM]: 'h-8',
};

export const SPINNER_COLOR_MAP = {
  [OUTLINE]: PRIMARY,
  [PRIMARY]: PRIMARY,
  [ALTERNATIVE]: PRIMARY,
};
</script>

<script setup>
import { computed } from 'vue';

import EpIcon from '../EpIcon';
import EpSpinner from '../EpSpinner';

const emit = defineEmits(['focus']);

const props = defineProps({
  color: {
    type: String,
    default: PRIMARY,
    validator: (value) => Object.keys(COLOR_CLASS_MAP).includes(value),
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  leftIcon: {
    type: Boolean,
    default: false,
  },

  icon: {
    type: String,
    default: '',
  },

  loading: {
    default: false,
    type: Boolean,
  },

  size: {
    type: String,
    default: BASE,
    validator: (value) => SIZES.includes(value),
  },

  tag: {
    default: 'button',
    type: [Object, String],
  },

  trailing: {
    type: Boolean,
    default: false,
  },
});

const colorClasses = computed(() => {
  return props.disabled || props.loading
    ? 'bg-gray-100 dark:bg-gray-700 cursor-not-allowed text-gray-400 outline-none focus:ring-0'
    : COLOR_CLASS_MAP[props.color];
});
const loadingClasses = computed(() => {
  return { 'opacity-0': props.loading };
});
const sizeClasses = computed(() => {
  return SIZE_CLASS_MAP[props.size];
});
const spinnerColor = computed(() => {
  return SPINNER_COLOR_MAP[props.color];
});
</script>
